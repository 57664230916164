.ReactTable .rt-th {
    overflow: visible;
}


.CalendarDay__selected_span {
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
}

/* Will edit when hovered over. _span style also has this property*/
.CalendarDay__selected:hover {
}

/* Will edit when the second date (end date) in a range of dates
 is not yet selected. Edits the dates between your mouse and said date*/
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
}

.CalendarMonth_table {
    font-weight: normal;
}

.CalendarMonth_table th, .CalendarMonth_table td {
    padding: 1px;
    vertical-align: middle;
}

.fill {
    min-height: 100%;
    height: 100%;
}

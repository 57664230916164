.Login {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
    height: 100%;
}

.Login__form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
}

.Login__form-signin .checkbox {
    font-weight: 400;
}

.Login__form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}

.Login__form-signin .form-control:focus {
    z-index: 2;
}

.Login__form-signin .form-control:focus {
    z-index: 2;
}

.Login__form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.MainLayout_navbar-background {
    background-color: #02aff1;
}

.MainLayout__vatlynx-logo {
    max-height: 35px;
}

.MainLayout__user-dropdown-toggle {
    color: white;
}

body {
    font-size: .875rem;
}

.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}

.sidebar .nav-link {
    font-weight: 500;
    color: #333;
}

.sidebar .nav-link .feather {
    margin-right: 4px;
    color: #999;
}

.sidebar .nav-link.active {
    color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
    color: inherit;
}

.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
}

/*
 * Content
 */

[role="main"] {
    padding-top: 48px; /* Space for fixed navbar */
}

/*
 * Navbar
 */

.navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
}

.form-control-dark {
    color: #fff !important;
    background-color: rgba(255, 255, 255, .1) !important;
    border-color: rgba(255, 255, 255, .1) !important;
}

.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}
#calendar-timeline {
    padding-left: 0;
}

#calendar-timeline-header {
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 40px;
    left: 240px;
    z-index: 1020;
    border-bottom: 1px solid rgba(1, 1, 1, 0.3);
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
}

#calendar-timeline-footer {
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    left: 240px;
    z-index: 1020;
    border-top: 1px solid rgba(1, 1, 1, 0.3);
    border-left: 1px solid rgba(1, 1, 1, 0.3);
    border-right: 1px solid rgba(1, 1, 1, 0.3);
    height: 4rem;
    display: grid;
    grid-template-columns: auto 149px;
}

.calendar-timeline-container {
    display: grid;
    grid-template-columns: 100px 150px auto;
}

.calendar-timeline-day-container {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    position: -webkit-sticky;
    position: sticky;
    top: calc(4rem + 40px);
}

.calendar-timeline-group-info {
    position: relative;
    border-top: 1px solid rgba(1, 1, 1, 0.3);
    border-right: 1px solid rgba(1, 1, 1, 0.3);
}

.calendar-timeline-day-outer-container {
    position: -webkit-sticky;
    position: sticky;
    border-left: 1px solid rgba(1, 1, 1, 0.3);
    border-top: 1px solid rgba(1, 1, 1, 0.3);
    border-right: 1px solid rgba(1, 1, 1, 0.3);
    background-color: white;
    z-index: 1000;
    left: 0;
}

#calendar-timeline > .calendar-timeline-container:first-child .calendar-timeline-day-outer-container {
    border-top: 0;
}

#calendar-timeline > .calendar-timeline-container:last-child .calendar-timeline-day-outer-container {
    border-bottom: 1px solid rgba(1, 1, 1, 0.3);
}

.calendar-timeline-hours-container {
    display: grid;
    grid-template-columns: repeat(24, 60px) 150px;
    height: 100%;
    border-top: 1px solid rgba(1, 1, 1, 0.3);
    position: relative;
    overflow-x: scroll;
}

.calendar-total {
    position: -webkit-sticky;
    position: sticky;
    right: 0;
    background-color: white;
    border-right: 1px solid rgba(1, 1, 1, 0.3);
    border-left: 1px solid rgba(1, 1, 1, 0.3);
    z-index: 900;
}

.calendar-timeline-hours-container::-webkit-scrollbar {
    display: none;
}

#calendar-timeline > .calendar-timeline-container:last-child .calendar-timeline-hours-container {
    border-bottom: 1px solid rgba(1, 1, 1, 0.3);
}

.calendar-timeline-hour {
    border-left: 1px solid rgba(1, 1, 1, 0.3);
    padding-left: 3px;
}

#calendar-timeline > .calendar-timeline-container > .calendar-timeline-hours-container > .calendar-timeline-hour:first-child {
    border-left: 0;
}

.calendar-timeline-hour:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.calendar-timeline-total-container {
    border-left: 1px solid rgba(1, 1, 1, 0.3);
    grid-column-start: 2;
    padding-right: 10px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.month-total-hours {
    font-size: 2rem !important;
}

.ReactTable .rt-th {
    overflow: visible;
}


.CalendarDay__selected_span {
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
}

/* Will edit when hovered over. _span style also has this property*/
.CalendarDay__selected:hover {
}

/* Will edit when the second date (end date) in a range of dates
 is not yet selected. Edits the dates between your mouse and said date*/
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
}

.CalendarMonth_table {
    font-weight: normal;
}

.CalendarMonth_table th, .CalendarMonth_table td {
    padding: 1px;
    vertical-align: middle;
}

.fill {
    min-height: 100%;
    height: 100%;
}


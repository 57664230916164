#calendar-timeline {
    padding-left: 0;
}

#calendar-timeline-header {
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 40px;
    left: 240px;
    z-index: 1020;
    border-bottom: 1px solid rgba(1, 1, 1, 0.3);
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
}

#calendar-timeline-footer {
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    left: 240px;
    z-index: 1020;
    border-top: 1px solid rgba(1, 1, 1, 0.3);
    border-left: 1px solid rgba(1, 1, 1, 0.3);
    border-right: 1px solid rgba(1, 1, 1, 0.3);
    height: 4rem;
    display: grid;
    grid-template-columns: auto 149px;
}

.calendar-timeline-container {
    display: grid;
    grid-template-columns: 100px 150px auto;
}

.calendar-timeline-day-container {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    position: -webkit-sticky;
    position: sticky;
    top: calc(4rem + 40px);
}

.calendar-timeline-group-info {
    position: relative;
    border-top: 1px solid rgba(1, 1, 1, 0.3);
    border-right: 1px solid rgba(1, 1, 1, 0.3);
}

.calendar-timeline-day-outer-container {
    position: -webkit-sticky;
    position: sticky;
    border-left: 1px solid rgba(1, 1, 1, 0.3);
    border-top: 1px solid rgba(1, 1, 1, 0.3);
    border-right: 1px solid rgba(1, 1, 1, 0.3);
    background-color: white;
    z-index: 1000;
    left: 0;
}

#calendar-timeline > .calendar-timeline-container:first-child .calendar-timeline-day-outer-container {
    border-top: 0;
}

#calendar-timeline > .calendar-timeline-container:last-child .calendar-timeline-day-outer-container {
    border-bottom: 1px solid rgba(1, 1, 1, 0.3);
}

.calendar-timeline-hours-container {
    display: grid;
    grid-template-columns: repeat(24, 60px) 150px;
    height: 100%;
    border-top: 1px solid rgba(1, 1, 1, 0.3);
    position: relative;
    overflow-x: scroll;
}

.calendar-total {
    position: -webkit-sticky;
    position: sticky;
    right: 0;
    background-color: white;
    border-right: 1px solid rgba(1, 1, 1, 0.3);
    border-left: 1px solid rgba(1, 1, 1, 0.3);
    z-index: 900;
}

.calendar-timeline-hours-container::-webkit-scrollbar {
    display: none;
}

#calendar-timeline > .calendar-timeline-container:last-child .calendar-timeline-hours-container {
    border-bottom: 1px solid rgba(1, 1, 1, 0.3);
}

.calendar-timeline-hour {
    border-left: 1px solid rgba(1, 1, 1, 0.3);
    padding-left: 3px;
}

#calendar-timeline > .calendar-timeline-container > .calendar-timeline-hours-container > .calendar-timeline-hour:first-child {
    border-left: 0;
}

.calendar-timeline-hour:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.calendar-timeline-total-container {
    border-left: 1px solid rgba(1, 1, 1, 0.3);
    grid-column-start: 2;
    padding-right: 10px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.month-total-hours {
    font-size: 2rem !important;
}
